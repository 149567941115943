import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Importa useNavigate da react-router-dom
import { signOut } from "firebase/auth"; // Importa signOut da Firebase auth
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FaCamera, FaUpload } from "react-icons/fa";
import NavBar from "./Navbar";
import logo from "../img/logo.png";
import "../styles/profilo.css";

const Profile = () => {
  const [profile, setProfile] = useState({});
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const fetchProfile = async () => {
          try {
            const profileRef = doc(db, "users", user.uid);
            const profileSnap = await getDoc(profileRef);
            if (profileSnap.exists()) {
              const profileData = profileSnap.data();
              setProfile(profileData);
              if (profileData.photoURL) {
                setProfilePictureURL(profileData.photoURL);
              }
            } else {
              console.log("Nessun profilo trovato!");
            }
          } catch (error) {
            console.error("Errore nel recupero del profilo:", error);
          }
        };

        const fetchUserRole = async (userId) => {
          const userDocRef = doc(db, "users", userId);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            setUserRole(userDocSnap.data().role);
          } else {
            console.log("Nessun documento utente trovato!");
          }
        };

        fetchProfile();
        fetchUserRole(user.uid);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  const handleProfilePictureChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
      setIsUploading(true);
    }
  };

  const handleUploadProfilePicture = async () => {
    if (!profilePicture) return;
    const user = auth.currentUser;
    if (!user) return;

    const storageRef = ref(storage, `profilePictures/${user.uid}`);
    await uploadBytes(storageRef, profilePicture);
    const downloadURL = await getDownloadURL(storageRef);

    const profileRef = doc(db, "users", user.uid);
    await updateDoc(profileRef, { photoURL: downloadURL });
    setProfilePictureURL(downloadURL);
    setIsUploading(false);
    setProfilePicture(null);
  };

  // Funzione per gestire il logout
  const handleLogout = async () => {
    try {
      await signOut(auth); // Esegue il logout da Firebase
      navigate("/"); // Reindirizza alla home page
    } catch (error) {
      console.error("Errore durante il logout:", error);
    }
  };

  // All'interno del componente, dopo la dichiarazione delle funzioni
  const navigate = useNavigate();

  return (
    <div>
      <div className="dashboard-header">
        <img className="logo" src={logo} alt="Logo" />
        <h2 style={{ marginTop: "10px" }} className="dashboard-title">
          Profilo
        </h2>
      </div>
      <div className="profile-container-image">
        <div
          className="profile-picture-container"
          style={{
            backgroundImage: `url(${profilePictureURL || ""})`,
          }}
          onClick={() => document.getElementById("file").click()}
        >
          {!isUploading && <FaCamera className="profile-picture-icon" />}
          {isUploading && <FaUpload className="profile-picture-icon" />}
        </div>
        <div className="upload-container">
          <input
            type="file"
            id="file"
            className="input-file"
            onChange={handleProfilePictureChange}
          />
          {isUploading && (
            <button
              className="button-upload"
              onClick={handleUploadProfilePicture}
            >
              Carica Immagine
            </button>
          )}
        </div>
      </div>

      <div className="profile-container-info">
        <h3 className="name-profile">{profile.name}</h3>
        <span className="mail-profile">{profile.email}</span>
      </div>
      <div className="profile-container-logout-button">
        <button onClick={handleLogout} className="logout-button-profile">
          Logout
        </button>
      </div>
      <p className="role-profile">{profile.role}</p>
      <div className="profile-details-container">
        {userRole === "pr" ? (
          <>
            <div className="card-profile">
              <p>{profile.points}</p>
              <h3>Punti</h3>
            </div>
            <div className="card-profile">
              <p>{profile.earn}€</p>
              <h3>Guadagno</h3>
            </div>
          </>
        ) : (
          <>
            <div className="card-profile">
              <p>{profile.points}</p>
              <h3>Punti</h3>
            </div>
            <div className="card-profile">
              <p>{profile.debit}€</p> <h3>Da pagare</h3>
            </div>
          </>
        )}
      </div>
      <NavBar userRole={userRole} />
    </div>
  );
};

export default Profile;
