// src/components/Register.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { setDoc, doc, collection, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
import "../styles/register.css";
import logo from "../img/logo.png";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("pr");
  const points = 0;
  const earn = 0;
  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      // Fetch all users from Firestore
      const usersRef = collection(db, "users");
      const querySnapshot = await getDocs(usersRef);

      // Check if the name already exists in a case-insensitive manner
      const nameExists = querySnapshot.docs.some(
        (doc) => doc.data().name.toLowerCase() === name.toLowerCase()
      );

      if (nameExists) {
        alert("Il nome utente è già in uso. Scegli un altro nome.");
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Update the user's profile with the name
      await updateProfile(user, {
        displayName: name,
      });

      // Save additional user information in Firestore
      await setDoc(doc(db, "users", user.uid), {
        name,
        email,
        role,
        points: Number(points),
        earn: Number(earn),
      });

      // Navigate to the dashboard or show a success message
      navigate("/dashboard"); // Ensure the path is correct
    } catch (error) {
      console.error("Errore nella registrazione:", error.message);
      // Handle the error by showing a message to the user
    }
  };

  return (
    <div className="register-container">
      <div className="register-form">
        <img className="logo" src={logo} alt="Logo" />
        <input
          className="register-input"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
        />
        <input
          className="register-input"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          className="register-input"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <select
          className="register-select"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          <option value="pr">PR</option>
          <option value="organizer">Organizer</option>
        </select>
        <button className="register-button" onClick={handleRegister}>
          Register
        </button>
        <p>
          Hai già un account?{" "}
          <Link className="account" to="/login">
            Accedi
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
