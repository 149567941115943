import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "../styles/givepoints.css";
import NavBar from "./Navbar";
import logo from "../img/logo.png";

const GivePoints = () => {
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [prUsers, setPrUsers] = useState([]);
  const [pointsPerParticipant, setPointsPerParticipant] = useState(2); // Punti per partecipante presente
  const [commission, setCommission] = useState(0);
  const [participants, setParticipants] = useState([]); // Stato per i partecipanti
  const [userRole, setUserRole] = useState("");
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        if (user.displayName) {
          fetchEvents(user.displayName);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchEvents = async (displayName) => {
    try {
      const eventsRef = collection(db, "events");
      const q = query(eventsRef, where("createdBy", "==", displayName));
      const querySnapshot = await getDocs(q);

      const eventsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setEvents(eventsList);
    } catch (error) {
      console.error("Errore nel recupero degli eventi:", error);
    }
  };

  const fetchPrUsersAndCommission = async (eventId) => {
    try {
      console.log("Selected Event ID:", eventId);

      const eventRef = doc(db, "events", eventId);
      const eventSnap = await getDoc(eventRef);

      if (eventSnap.exists()) {
        const eventData = eventSnap.data();
        setCommission(Number(eventData.commission) || 0); // Assicurati che la commissione sia un numero

        // Recupera i PR iscritti dalla sotto-collezione 'enrolledUsers'
        const enrolledUsersRef = collection(
          db,
          "events",
          eventId,
          "enrolledUsers"
        );
        const enrolledUsersSnap = await getDocs(enrolledUsersRef);
        const enrolledUsersList = enrolledUsersSnap.docs.map((doc) => ({
          id: doc.id,
          userId: doc.data().userId,
          displayName: doc.data().displayName,
          role: doc.data().role,
        }));

        setPrUsers(enrolledUsersList);

        // Recupera i partecipanti dall'evento
        const participantsRef = collection(
          db,
          "events",
          eventId,
          "participants"
        );
        const participantsSnap = await getDocs(participantsRef);
        const participantsList = participantsSnap.docs.map((doc) => doc.data());

        setParticipants(participantsList);

        console.log("Enrolled Users:", enrolledUsersList);
        console.log("Participants:", participantsList);
      } else {
        console.log("Nessun evento trovato con ID:", eventId);
      }
    } catch (error) {
      console.error(
        "Errore durante il recupero degli utenti PR e delle commissioni:",
        error
      );
    }
  };

  const handleEventChange = (event) => {
    const eventId = event.target.value;
    setSelectedEventId(eventId);
    fetchPrUsersAndCommission(eventId);
  };

  const handleAssignPoints = async () => {
    try {
      console.log("Selected Event ID:", selectedEventId);

      const eventRef = doc(db, "events", selectedEventId);
      const eventSnap = await getDoc(eventRef);

      if (!eventSnap.exists()) {
        console.log("Evento non trovato:", selectedEventId);
        return;
      }

      const eventData = eventSnap.data();
      const commission = Number(eventData.commission) || 0; // Assicurati che la commissione sia un numero

      for (const prUser of prUsers) {
        const userId = prUser.userId;
        console.log(`Processing User ID: ${userId}`);

        const prParticipantCount = participants.filter(
          (participant) =>
            participant.prName.toLowerCase() ===
            prUser.displayName.toLowerCase()
        ).length;

        const prParticipantPresentCount = participants.filter(
          (participant) =>
            participant.prName.toLowerCase() ===
              prUser.displayName.toLowerCase() && participant.presente === "si"
        ).length;

        console.log(
          `PR User: ${prUser.displayName}, Total Participants: ${prParticipantCount}, Present Count: ${prParticipantPresentCount}`
        );

        const totalCommission = commission * prParticipantPresentCount;
        const totalPoints = pointsPerParticipant * prParticipantPresentCount;

        console.log(
          `Total Commission for ${prUser.displayName}: ${totalCommission}`
        );
        console.log(`Total Points for ${prUser.displayName}: ${totalPoints}`);

        await updatePointsAndEarningsForUser(
          userId,
          totalPoints,
          totalCommission
        );
      }

      // Aggiungi la commissione come debito allo user corrente
      if (currentUser) {
        await updateDebtsForCurrentUser(currentUser.uid, commission);
      }

      alert("Punti e guadagni assegnati con successo!");
    } catch (error) {
      console.error(
        "Errore durante l'assegnazione dei punti e delle commissioni:",
        error
      );
      alert(
        "Si è verificato un errore durante l'assegnazione dei punti e delle commissioni."
      );
    }
  };

  const updatePointsAndEarningsForUser = async (
    userId,
    pointsToAdd,
    totalCommission
  ) => {
    try {
      console.log(`Updating points and earnings for user ID: ${userId}`);
      console.log(
        `Points to Add: ${pointsToAdd}, Total Commission: ${totalCommission}`
      );

      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        const newPoints = (userData.points || 0) + pointsToAdd;
        const newEarnings = (userData.earn || 0) + totalCommission;

        console.log(`New Points: ${newPoints}, New Earnings: ${newEarnings}`);

        await updateDoc(userRef, { points: newPoints, earn: newEarnings });
        console.log(
          "Punti e guadagni aggiornati con successo per l'utente:",
          userId
        );
      } else {
        console.log("Nessun utente trovato con lo ID specificato:", userId);
      }
    } catch (error) {
      console.error(
        "Errore durante l'aggiornamento dei punti e guadagni per l'utente:",
        error
      );
    }
  };

  const updateDebtsForCurrentUser = async (userId, totalCommission) => {
    try {
      console.log(`Updating debit for current user ID: ${userId}`);
      console.log(`Total Commission to Add: ${totalCommission}`);

      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        const currentDebit = Number(userData.debit) || 0; // Assicurati che il debito sia un numero
        const newDebit = currentDebit + totalCommission;

        console.log(`New Debit: ${newDebit}`);

        await updateDoc(userRef, { debit: newDebit });
        console.log(
          "Debito aggiornato con successo per l'utente corrente:",
          userId
        );
      } else {
        console.log("Nessun utente trovato con lo ID specificato:", userId);
      }
    } catch (error) {
      console.error(
        "Errore durante l'aggiornamento del debito per l'utente corrente:",
        error
      );
    }
  };

  return (
    <div>
      <div className="dashboard-header">
        <img className="logo" src={logo} alt="Logo" />
        <h2 className="dashboard-title">Paga PR</h2>
      </div>
      <div className="container-give-points">
        <div className="info-give-points">
          <select
            className="select-event-give"
            value={selectedEventId}
            onChange={handleEventChange}
          >
            <option value="">Seleziona un evento</option>
            {events.map((event) => (
              <option key={event.id} value={event.id}>
                {event.title}
              </option>
            ))}
          </select>

          {prUsers.length > 0 && (
            <div className="participants-list-give">
              <h3>PR iscritti all'evento selezionato:</h3>
              <div className="card-container-give">
                {prUsers.map((prUser, index) => {
                  const prParticipantCount = participants.filter(
                    (participant) =>
                      participant.prName.toLowerCase() ===
                      prUser.displayName.toLowerCase()
                  ).length;

                  const prParticipantPresentCount = participants.filter(
                    (participant) =>
                      participant.prName.toLowerCase() ===
                        prUser.displayName.toLowerCase() &&
                      participant.presente === "si"
                  ).length;

                  const totalPoints =
                    pointsPerParticipant * prParticipantPresentCount;
                  const totalCommission =
                    commission * prParticipantPresentCount;

                  return (
                    <div className="card-give" key={index}>
                      <b style={{ color: "#7209b7" }}> {prUser.displayName}</b>{" "}
                      <b>{totalCommission}€</b> & <b>{totalPoints}pts</b>
                      <br></br>
                      <br></br> Totali: <b>{prParticipantCount} </b>
                      <br></br>Presenti:
                      <b>{prParticipantPresentCount}</b>
                    </div>
                  );
                })}
              </div>
              <button
                className="button-give-points"
                onClick={handleAssignPoints}
              >
                Assegna Punti e Guadagni
              </button>
            </div>
          )}
        </div>
      </div>
      <NavBar userRole={userRole} />
    </div>
  );
};

export default GivePoints;
