// src/components/CreateEvent.js
import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "../styles/crea-evento.css"; // Assicurati che il percorso sia corretto
import logo from "../img/logo.png";
import NavBar from "./Navbar";

const CreateEvent = () => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [commission, setCommission] = useState(0);
  const [image, setImage] = useState(null);
  const [userDisplayName, setUserDisplayName] = useState("");
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [userRole, setUserRole] = useState("");
  const [endTime, setEndTime] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserDisplayName(user.displayName || "Utente Anonimo");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!image) {
      alert("Per favore, carica un'immagine per l'evento.");
      return;
    }

    const storage = getStorage();
    const storageRef = ref(storage, `event_images/${image.name}`);
    const uploadTask = await uploadBytes(storageRef, image);

    const imageUrl = await getDownloadURL(uploadTask.ref);

    const db = getFirestore();
    const docRef = await addDoc(collection(db, "events"), {
      title,
      date,
      time,
      endTime,
      location,
      address,
      price,
      commission,
      imageUrl,
      description,
      createdBy: userDisplayName,
    });

    console.log("Documento scritto con ID: ", docRef.id);
    // Resetta lo stato o reindirizza l'utente
  };

  return (
    <div>
      <div className="create-event-header">
        <img className="logo" src={logo} alt="Logo" />
        <h2 className="create-event-title">Crea evento</h2>
      </div>
      <div className="container-create-event">
        <div className="form-group-create-event">
          <form onSubmit={handleSubmit}>
            <label>Titolo Evento</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Event Title"
            />
            <div>
              <label>Descrizione:</label>
              <textarea
                className="textarea-style"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <label>Data Evento</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <div>
              <label>Ora inizio:</label>
              <input
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
            <div>
              <label>Ora fine:</label>
              <input
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </div>
            <label>Location</label>
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Location"
            />
            <div>
              <label>Indirizzo:</label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <label>Prezzo</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Costo serata"
            />
            <label>Provvigione PR</label>
            <input
              type="number"
              value={commission}
              onChange={(e) => setCommission(e.target.value)}
              placeholder="Provvigione per persona"
            />
            <div>
              <label>Immagine:</label>
              <input type="file" onChange={handleImageChange} />
            </div>
            <button className="button-submit-event" type="submit">
              Crea Evento
            </button>
          </form>
        </div>
      </div>
      <NavBar userRole={userRole} />
    </div>
  );
};

export default CreateEvent;
