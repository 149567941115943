import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import NavBar from "./Navbar";
import "../styles/ranking.css";
import logo from "../img/logo.png";

const Ranking = () => {
  const [ranking, setRanking] = useState([]);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const fetchRanking = async () => {
      const q = query(
        collection(db, "users"),
        orderBy("points", "desc")
        // Rimuovi limit(10) per includere tutti gli utenti
      );
      const querySnapshot = await getDocs(q);
      const rankingData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRanking(rankingData);
    };

    fetchRanking();
  }, []);

  return (
    <div>
      <div className="dashboard-header">
        <img className="logo" src={logo} alt="Logo" />
        <h2 style={{ marginTop: "10px" }} className="dashboard-title">
          Ranking
        </h2>
      </div>
      <div className="ranking-container">
        <ol>
          {ranking.map((user) => (
            <li key={user.id} className="ranking-item">
              <span className="ranking-name">{user.name}</span>
              <span className="ranking-points">{user.points} punti</span>
            </li>
          ))}
        </ol>
        <NavBar userRole={userRole} />
      </div>
    </div>
  );
};

export default Ranking;
