// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import CreateEvent from "./components/CreateEvent";
import EventDetails from "./components/EventDetails";
import ParticipantList from "./components/ParticipantList";
import Ranking from "./components/Ranking";
import GivePoins from "./components/givepoints";
import MyEvents from "./components/MyEvents";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/create-event" element={<CreateEvent />} />
        <Route path="/event/:id" element={<EventDetails />} />
        <Route path="/event/:id/participants" element={<ParticipantList />} />
        <Route path="/ranking" element={<Ranking />} />
        <Route path="/givepoints" element={<GivePoins />} />
        <Route path="/myevents" element={<MyEvents />} />
      </Routes>
    </Router>
  );
};

export default App;
