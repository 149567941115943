import React, { useEffect, useState } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import { Link } from "react-router-dom";
import "../styles/dashboard.css";
import NavBar from "./Navbar";
import logo from "../img/logo.png";
import { PiMapPinDuotone } from "react-icons/pi";
import { PiClockDuotone } from "react-icons/pi";
import { PiCalendarDuotone } from "react-icons/pi";

const MyEvents = () => {
  const [events, setEvents] = useState([]);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const fetchUserRole = async (userId) => {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        // Assumendo che il campo che contiene il ruolo si chiami "role"
        setUserRole(userDocSnap.data().role);
      } else {
        console.log("Nessun documento utente trovato!");
      }
    };

    const fetchEvents = async (displayName) => {
      const eventsCollectionRef = collection(db, "events");
      const eventsSnapshot = await getDocs(eventsCollectionRef);

      const filteredEvents = [];

      for (const eventDoc of eventsSnapshot.docs) {
        const eventData = eventDoc.data();
        const enrolledUsersRef = collection(
          db,
          "events",
          eventDoc.id,
          "enrolledUsers"
        );
        const enrolledUsersSnapshot = await getDocs(enrolledUsersRef);
        const enrolledUsers = enrolledUsersSnapshot.docs.map((doc) =>
          doc.data()
        );

        if (enrolledUsers.some((user) => user.displayName === displayName)) {
          filteredEvents.push({ id: eventDoc.id, ...eventData });
        }
      }

      setEvents(filteredEvents);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchUserRole(user.uid); // Recupera il ruolo dell'utente
        fetchEvents(user.displayName); // Recupera gli eventi a cui l'utente è iscritto
      } else {
        setUser(null);
        setUserRole(""); // Resetta il ruolo se l'utente non è loggato
      }
    });

    return () => unsubscribe(); // Pulizia alla dismontaggio del componente
  }, []);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <div className="dashboard-header">
        <img className="logo" src={logo} alt="Logo" />
        <h2 className="dashboard-title">I tuoi eventi</h2>
      </div>
      <p className="welcome">
        <span className="role">{userRole}</span>, <b>{user.displayName}</b>
      </p>
      {userRole === "pr" && (
        <h2 className="eventi-title">
          Eventi <span className="disponibili">a cui sei iscritto</span>
        </h2>
      )}
      {userRole === "pr" && (
        <div className="cards-container">
          {events.map((event) => (
            <Link
              to={`/event/${event.id}`}
              key={event.id}
              className="card-link"
            >
              <div className="card">
                <div
                  className="event-image"
                  style={{
                    backgroundImage: `url(${event.imageUrl})`,
                    backgroundSize: "cover",
                    height: "200px",
                    width: "100%",
                    borderRadius: "10px 10px 10px 10px",
                    marginBottom: "20px",
                  }}
                ></div>
                <div className="card-event-info">
                  <p className="event-title">{event.title}</p>
                  <p className="event-location">
                    <PiMapPinDuotone /> <b>{event.location} </b>
                    <br></br>
                    <span>{event.address}</span>
                  </p>
                  <div className="Date-fee">
                    <p>
                      <span
                        style={{
                          fontSize: "2rem",
                          fontWeight: "bold",
                          color: "#7209b7",
                        }}
                      >
                        {event.commission}€
                      </span>

                      <span style={{ fontSize: "1rem" }}> /persona</span>
                    </p>
                    <p style={{ textAlign: "right" }}>
                      <b> {event.date}</b>
                      <PiCalendarDuotone />
                      <br></br>
                      {event.time} - {event.endTime}
                      <PiClockDuotone />
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}

      <NavBar userRole={userRole} />
    </div>
  );
};

export default MyEvents;
