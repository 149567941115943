import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  getDocs,
  query,
  doc,
  deleteDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "../styles/partecipants.css";
import { PiTrashSimpleDuotone, PiArrowCircleLeftDuotone } from "react-icons/pi";
import { Link } from "react-router-dom";

const ParticipantList = () => {
  const { id } = useParams();
  const [participants, setParticipants] = useState([]);
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [prParticipantCount, setPrParticipantCount] = useState(0);
  const [eventData, setEventData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [prSearchTerm, setPrSearchTerm] = useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setTotalCount(participants.length);
  }, [participants]);

  useEffect(() => {
    setPrParticipantCount(participants.length);
  }, [participants]);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const docRef = doc(db, "events", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setEventData(docSnap.data());
        } else {
          console.log("Nessun dato trovato per l'evento!");
        }
      } catch (error) {
        console.error("Errore nel recupero dei dati dell'evento:", error);
      }
    };

    fetchEventData();
  }, [id]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setDisplayName(user.displayName || "Utente senza nome");

        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUserRole(userData.role || "Ruolo predefinito");
        }
      } else {
        setDisplayName("");
      }
    });
  }, []);

  useEffect(() => {
    const fetchParticipants = async () => {
      const q = query(collection(db, "events", id, "participants"));
      const querySnapshot = await getDocs(q);
      const participantsData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setParticipants(participantsData);

      const count = participantsData.filter(
        (participant) => participant.prName === displayName
      ).length;
      setPrParticipantCount(count);
    };

    fetchParticipants();
  }, [id, displayName]);

  const handleAddParticipant = async () => {
    try {
      const prDisplayName = displayName;

      const newParticipantRef = await addDoc(
        collection(db, "events", id, "participants"),
        {
          name,
          prName: prDisplayName,
        }
      );

      setParticipants((prevParticipants) => [
        ...prevParticipants,
        { name, prName: prDisplayName, id: newParticipantRef.id },
      ]);
      setName("");
    } catch (error) {
      console.error("Error adding participant:", error);
    }
  };

  const handleDeleteParticipant = async (index) => {
    try {
      const participantToDelete = participants[index];
      if (!displayName) {
        console.error("displayName non è definito o è vuoto.");
        return;
      }
      if (participantToDelete.prName === displayName) {
        console.log(
          `Eliminazione del partecipante: ${participantToDelete.name}`
        );

        await deleteDoc(
          doc(db, "events", id, "participants", participantToDelete.id)
        );

        const updatedParticipants = participants.filter((_, i) => i !== index);
        setParticipants(updatedParticipants);
      } else {
        console.error("Non hai il permesso di eliminare questo partecipante.");
      }
    } catch (error) {
      console.error("Errore durante l'eliminazione del partecipante:", error);
    }
  };

  const handleConfirmPresence = async (participantId, isPresent) => {
    try {
      const participantRef = doc(
        db,
        "events",
        id,
        "participants",
        participantId
      );
      await updateDoc(participantRef, {
        presente: isPresent ? "si" : "no",
      });

      setParticipants((prevParticipants) =>
        prevParticipants.map((participant) =>
          participant.id === participantId
            ? { ...participant, presente: isPresent ? "si" : "no" }
            : participant
        )
      );
    } catch (error) {
      console.error("Errore durante l'aggiornamento della presenza:", error);
    }
  };

  const sortedParticipants = participants.sort((a, b) => {
    if (a.isAddedByMe && !b.isAddedByMe) {
      return -1;
    }
    if (!a.isAddedByMe && b.isAddedByMe) {
      return 1;
    }
    return 0;
  });

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePrSearchChange = (e) => {
    setPrSearchTerm(e.target.value);
  };

  const filteredParticipants = sortedParticipants.filter((participant) =>
    participant.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredByPrParticipants = sortedParticipants.filter((participant) =>
    participant.prName.toLowerCase().includes(prSearchTerm.toLowerCase())
  );

  const finalFilteredParticipants =
    userRole === "organizer" ? filteredByPrParticipants : filteredParticipants;

  return (
    <div>
      <div className="dashboard-header">
        <Link to={`/event/${id}`} style={{ paddingLeft: "20px" }}>
          <PiArrowCircleLeftDuotone className="iconback-event-list" />
        </Link>

        {eventData ? (
          <div>
            <p className="header-list">
              <span className="sub-title-list">Lista evento</span>
              <br></br>
              <span className="title-list">{eventData.title}</span>
            </p>
          </div>
        ) : (
          <p>Caricamento dei dati dell'evento...</p>
        )}
      </div>
      <div className="participant-list-container">
        <input
          className="input-field"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nome e cognome partecipante"
        />
        <button
          className="button add-participant-button"
          onClick={handleAddParticipant}
        >
          Aggiungi
        </button>
        <div className="partecipanti-list-header">
          <div>I tuoi partecipanti: {prParticipantCount}</div>
          <div>Totali: {totalCount}</div>
        </div>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Cerca partecipante"
          className="search-input-list"
        />
        {userRole === "organizer" && (
          <input
            type="text"
            value={prSearchTerm}
            onChange={handlePrSearchChange}
            placeholder="Cerca PR"
            className="search-input-list"
          />
        )}
        <ul className="participant-list">
          {finalFilteredParticipants.map((participant, index) => (
            <li className="participant-item" key={index}>
              <div className="participant-details">
                {userRole === "organizer" && (
                  <input
                    type="checkbox"
                    checked={participant.presente === "si"}
                    onChange={(e) =>
                      handleConfirmPresence(participant.id, e.target.checked)
                    }
                  />
                )}
                <b className="participant-name">{participant.name}</b> PR:{" "}
                {participant.prName}
              </div>
              {participant.prName === displayName && (
                <button
                  className="button delete-participant-button"
                  onClick={() => handleDeleteParticipant(index)}
                >
                  <PiTrashSimpleDuotone />
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ParticipantList;
