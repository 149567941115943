// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCIqVWFP9B6s2s7A8x1HwmnftlKa86etG8",
  authDomain: "appr-81e76.firebaseapp.com",
  projectId: "appr-81e76",
  storageBucket: "appr-81e76.appspot.com",
  messagingSenderId: "626260576599",
  appId: "1:626260576599:web:a95c4dea83d4f8880e1612",
  measurementId: "G-C55QJTFRM4",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);

export { auth, db, storage, messaging };
