import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import { Link } from "react-router-dom";
import "../styles/dashboard.css";
import NavBar from "./Navbar";
import logo from "../img/logo.png";
import { PiMapPinDuotone } from "react-icons/pi";
import { PiClockDuotone } from "react-icons/pi";
import { PiCalendarDuotone } from "react-icons/pi";

const Dashboard = () => {
  const [events, setEvents] = useState([]);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const fetchEvents = async (displayName, role) => {
      const eventsCollectionRef = collection(db, "events");
      let eventsSnapshot;

      if (role === "pr") {
        eventsSnapshot = await getDocs(eventsCollectionRef);
      } else if (role === "organizer") {
        const eventsQuery = query(
          eventsCollectionRef,
          where("createdBy", "==", displayName)
        );
        eventsSnapshot = await getDocs(eventsQuery);
      }

      if (eventsSnapshot) {
        const eventsList = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventsList);
      }
    };

    const fetchUserRole = async (userId) => {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        return userDocSnap.data().role;
      } else {
        console.log("Nessun documento utente trovato!");
        return "";
      }
    };

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const role = await fetchUserRole(user.uid);
        setUserRole(role);
        if (user.displayName && role) {
          fetchEvents(user.displayName, role);
        }
      } else {
        setUser(null);
        setUserRole("");
      }
    });

    return () => unsubscribe();
  }, []);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <div className="dashboard-header">
        <img className="logo" src={logo} alt="Logo" />
        <h2 className="dashboard-title">Dashboard</h2>
      </div>
      <p className="welcome">
        <span className="role">{userRole}</span>, <b>{user.displayName}</b>
      </p>
      {userRole === "pr" && (
        <h2 className="eventi-title">
          Eventi <span className="disponibili">Disponibili</span>
        </h2>
      )}
      {userRole === "pr" && (
        <div className="cards-container">
          {events.map((event) => (
            <Link
              to={`/event/${event.id}`}
              key={event.id}
              className="card-link"
            >
              <div className="card">
                <div
                  className="event-image"
                  style={{
                    backgroundImage: `url(${event.imageUrl})`,
                    backgroundSize: "cover",
                    height: "200px",
                    width: "100%",
                    borderRadius: "10px 10px 10px 10px",
                    marginBottom: "20px",
                  }}
                ></div>
                <div className="card-event-info">
                  <p className="event-title">{event.title}</p>
                  <p className="event-location">
                    <PiMapPinDuotone /> <b>{event.location} </b>
                    <br></br>
                    <span>{event.address}</span>
                  </p>
                  <div className="Date-fee">
                    <p>
                      <span
                        style={{
                          fontSize: "2rem",
                          fontWeight: "bold",
                          color: "#7209b7",
                        }}
                      >
                        {event.commission}€
                      </span>

                      <span style={{ fontSize: "1rem" }}> /persona</span>
                    </p>
                    <p style={{ textAlign: "right" }}>
                      <b> {event.date}</b>
                      <PiCalendarDuotone />
                      <br></br>
                      {event.time} - {event.endTime}
                      <PiClockDuotone />
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
      {userRole === "organizer" && (
        <h2 className="eventi-title">
          Eventi <span className="disponibili">Creati</span>
        </h2>
      )}
      {userRole === "organizer" && (
        <div className="cards-container">
          {events.map((event) => (
            <Link
              to={`/event/${event.id}`}
              key={event.id}
              className="card-link"
            >
              <div className="card">
                <div
                  className="event-image"
                  style={{
                    backgroundImage: `url(${event.imageUrl})`,
                    backgroundSize: "cover",
                    height: "200px",
                    width: "100%",
                    borderRadius: "10px 10px 10px 10px",
                    marginBottom: "20px",
                  }}
                ></div>
                <div className="card-event-info">
                  <p className="event-title">{event.title}</p>
                  <p className="event-location">
                    <PiMapPinDuotone /> <b>{event.location} </b>
                    <br></br>
                    <span>{event.address}</span>
                  </p>
                  <div className="Date-fee">
                    <p>
                      <span
                        style={{
                          fontSize: "2rem",
                          fontWeight: "bold",
                          color: "#7209b7",
                        }}
                      >
                        {event.commission}€
                      </span>

                      <span style={{ fontSize: "1rem" }}> /persona</span>
                    </p>
                    <p style={{ textAlign: "right" }}>
                      <b> {event.date}</b>
                      <PiCalendarDuotone />
                      <br></br>
                      {event.time} - {event.endTime}
                      <PiClockDuotone />
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
      <NavBar userRole={userRole} />
    </div>
  );
};

export default Dashboard;
