// src/components/Home.js
import React from "react";
import { Link } from "react-router-dom";
import "../styles/home.css";
import logo from "../img/logo.png";

const Home = () => (
  <div className="homediv">
    <img className="logo" src={logo} alt="Logo" />

    <p>
      <Link className="button-login-home" to="/login">
        Login
      </Link>
      <Link className="button-register-home" to="/register">
        Register
      </Link>
    </p>
  </div>
);

export default Home;
