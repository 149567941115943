// src/components/Login.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// Importa getAuth e signInWithEmailAndPassword
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "../styles/login.css";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Ottiene l'istanza di Auth
      const auth = getAuth();
      // eslint-disable-next-line
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Successo nel login, puoi accedere all'utente tramite userCredential.user
      navigate("/dashboard");
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  return (
    <div className="container">
      <div className="login-container">
        <div className="login-form">
          <img className="logo" src={logo} alt="Logo" />
          <input
            className="login-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <input
            className="login-input"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button className="login-button" onClick={handleLogin}>
            Login
          </button>
          <p>
            Non sei registrato?{" "}
            <Link className="non-registrato" to="/register">
              Registrati
            </Link>
          </p>
          <p className="password-dimenticata">
            Hai dimenticato la password?<br></br>{" "}
            <a className="password-dimenticata-link">Reimposta password</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
