import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { Link, useLocation } from "react-router-dom"; // Aggiungi useLocation
import "../styles/navbar.css";
import { PiRankingDuotone } from "react-icons/pi";
import { PiCalendarCheckDuotone } from "react-icons/pi";
import { PiUserDuotone } from "react-icons/pi";
import { PiHouseDuotone } from "react-icons/pi";
import { PiCalendarPlusDuotone } from "react-icons/pi";
import { PiMoney } from "react-icons/pi";

const NavBar = ({ userRole }) => {
  const [role, setRole] = useState(userRole || "guest"); // Utilizza userRole come stato iniziale se presente
  const auth = getAuth();
  const db = getFirestore();
  const location = useLocation(); // Ottiene il percorso attuale

  // Definizione di navigationItems con voci per "pr" e "organizer"
  const navigationItems = {
    pr: [
      {
        path: "/dashboard",
        name: "Home",
        icon: (
          <PiHouseDuotone
            className={`icon-nav ${
              location.pathname === "/dashboard" ? "active" : ""
            }`}
          />
        ),
      },
      {
        path: "/myevents",
        name: "Eventi",
        icon: (
          <PiCalendarCheckDuotone
            className={`icon-nav ${
              location.pathname === "/myevents" ? "active" : ""
            }`}
          />
        ),
      },
      {
        path: "/ranking",
        name: "Ranking",
        icon: (
          <PiRankingDuotone
            className={`icon-nav ${
              location.pathname === "/ranking" ? "active" : ""
            }`}
          />
        ),
      },
      {
        path: "/profile",
        name: "Profilo",
        icon: (
          <PiUserDuotone
            className={`icon-nav ${
              location.pathname === "/profile" ? "active" : ""
            }`}
          />
        ),
      },
    ],
    organizer: [
      {
        path: "/dashboard",
        name: "Eventi",
        icon: (
          <PiCalendarCheckDuotone
            className={`icon-nav ${
              location.pathname === "/dashboard" ? "active" : ""
            }`}
          />
        ),
      },
      {
        path: "/create-event",
        name: "Aggiungi",
        icon: (
          <PiCalendarPlusDuotone
            className={`icon-nav ${
              location.pathname === "/myevents" ? "active" : ""
            }`}
          />
        ),
      },
      {
        path: "/givepoints",
        name: "Paga",
        icon: (
          <PiMoney
            className={`icon-nav ${
              location.pathname === "/givepoints" ? "active" : ""
            }`}
          />
        ),
      },
      {
        path: "/profile",
        name: "Profilo",
        icon: (
          <PiUserDuotone
            className={`icon-nav ${
              location.pathname === "/profile" ? "active" : ""
            }`}
          />
        ),
      },
    ],
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userRef = doc(db, "users", user.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setRole(userSnap.data().role);
          }
        }
      } catch (error) {
        console.error("Errore nel recupero del ruolo dell'utente:", error);
        // Gestisci l'errore come preferisci (es. impostando un ruolo predefinito o mostrando un messaggio)
      }
    };

    if (!userRole) {
      fetchUserRole();
    }
  }, [userRole, auth, db]);

  // Utilizzo di 'role' per selezionare gli elementi di navigazione da 'navigationItems'
  const items = navigationItems[role] || [];

  // Renderizzazione dei componenti Link basati sugli 'items' selezionati
  return (
    <nav className="bottom-nav">
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <Link
              to={item.path}
              key={item.name}
              className={`nav-item ${
                location.pathname === item.path ? "active" : ""
              }`}
            >
              <div className="nav-item">
                {item.icon} {/* Renderizza l'icona */}
                <span>{item.name}</span>{" "}
                {/* Renderizza il nome dell'elemento sotto l'icona */}
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavBar;
