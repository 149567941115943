import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { db } from "../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "../styles/event-details.css";
import {
  PiArrowCircleLeftDuotone,
  PiMapPinDuotone,
  PiClockDuotone,
  PiCalendarDuotone,
} from "react-icons/pi";

const EventDetails = () => {
  const { id } = useParams();
  const [event, setEvent] = useState({});
  const [user, setUser] = useState(null);
  const auth = getAuth();
  const [displayName, setDisplayName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isEnrolled, setIsEnrolled] = useState(false);

  let resizeObserver;
  let resizeTimer;

  const debouncedResizeHandler = (entries) => {
    clearTimeout(resizeTimer);

    resizeTimer = setTimeout(() => {
      entries.forEach((entry) => {
        console.log("Element resized:", entry.target);
      });
    }, 100);
  };

  const observeResize = (element) => {
    if (resizeObserver) {
      resizeObserver.disconnect();
    }

    resizeObserver = new ResizeObserver(debouncedResizeHandler);
    resizeObserver.observe(element);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setDisplayName(user.displayName || "Nome predefinito");

        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUserRole(userData.role || "Ruolo predefinito");
          setUser(user);
        } else {
          setUserRole("Ruolo predefinito");
          setUser(user);
        }
      } else {
        setDisplayName("Nome predefinito");
        setUserRole("Ruolo predefinito");
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const fetchEvent = async () => {
      const cleanId = id.replace("id:", "");
      const docRef = doc(db, "events", cleanId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setEvent(docSnap.data());

        const enrolledUsersRef = collection(
          db,
          "events",
          cleanId,
          "enrolledUsers"
        );
        const enrolledUsersSnap = await getDocs(enrolledUsersRef);

        const enrolledUsers = enrolledUsersSnap.docs.map((doc) => doc.data());
        if (enrolledUsers.some((u) => u.userId === user?.uid)) {
          setIsEnrolled(true);
        } else {
          setIsEnrolled(false);
        }
      } else {
        console.log("No such document with ID:", cleanId);
      }
    };

    fetchEvent();
  }, [id, user]);

  const handleEnroll = async () => {
    if (!user) {
      console.error("Nessun utente loggato per l'iscrizione.");
      return;
    }

    const eventId = id.replace("id:", "");
    const enrolledUsersRef = collection(db, "events", eventId, "enrolledUsers");

    try {
      const enrolledUsersSnap = await getDocs(enrolledUsersRef);
      const enrolledUsers = enrolledUsersSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (isEnrolled) {
        const userDoc = enrolledUsers.find((u) => u.userId === user.uid);
        if (userDoc) {
          await deleteDoc(doc(enrolledUsersRef, userDoc.id));
          console.log("Utente rimosso con successo dall'evento.");
          setIsEnrolled(false);
        }
      } else {
        await addDoc(enrolledUsersRef, {
          userId: user.uid,
          displayName: displayName,
          role: userRole,
        });
        console.log("Utente iscritto con successo all'evento.");
        setIsEnrolled(true);
      }
    } catch (error) {
      console.error(
        "Errore durante l'iscrizione o rimozione dall'evento:",
        error
      );
    }
  };

  useEffect(() => {
    const element = document.querySelector(".event-details-container");
    if (element) {
      observeResize(element);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  if (!event) {
    console.error("Evento non trovato");
    return <div>Evento non trovato</div>;
  }

  return (
    <div>
      <Link
        to="/dashboard"
        style={{ position: "fixed", top: "20px", left: "20px" }}
      >
        <PiArrowCircleLeftDuotone className="iconback-event-details" />
      </Link>
      <div
        className="event-image"
        style={{
          backgroundImage: `url(${event.imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "50vh",
          width: "100%",
        }}
      ></div>
      <div className="event-details-container">
        <div className="event-info">
          <p className="event-title-details">{event.title}</p>
          <div className="date-hours-details-event">
            <p className="event-details-date">
              <PiCalendarDuotone /> {event.date}
            </p>
            <p className="event-details-date">
              <PiClockDuotone /> {event.time} - {event.endTime}
            </p>
          </div>

          <p className="event-details-location">
            <PiMapPinDuotone /> <b>{event.location}</b>
            <br></br>
            <span className="event-details-location">{event.address}</span>
          </p>

          <p className="event-details-date">
            <b>Descrizione:</b>
            <br></br>
            <span style={{ fontWeight: "normal" }}>{event.description}</span>
          </p>

          <p className="event-commission">
            <b>Prezzo:</b>
            <br></br> {event.price}€
          </p>

          <p className="event-commission">
            <b>Creato da:</b>
            <br></br> {event.createdBy}
          </p>
        </div>
        {(isEnrolled || userRole === "organizer") && (
          <Link to={`/event/${id}/participants`}>
            <button className="sub-button">Lista partecipanti</button>
          </Link>
        )}
      </div>

      <div className="sub-sez">
        <p>
          <span style={{ fontSize: "0.8rem" }}>Provvigione:</span>
          <br></br>
          <span
            style={{ fontSize: "2rem", fontWeight: "bold", color: "#7209b7" }}
          >
            {event.commission}€
          </span>
          <span style={{ fontSize: "0.8rem" }}> /persona</span>
        </p>
        {user && userRole === "pr" && (
          <button className="sub-button" onClick={handleEnroll}>
            {isEnrolled ? "Iscritto" : "Iscriviti"}
          </button>
        )}
        {user && userRole === "organizer" && (
          <Link to={`/event/${id}/edit`}>
            <button className="sub-button">Modifica</button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default EventDetails;
